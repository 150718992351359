import React, { useState, useEffect } from 'react';
import { Box, Heading, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, Text, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LineChart,
  Line,
  Cell,
  ReferenceLine,
  PieChart,
  Pie,
} from 'recharts';
import RankingListComponent from '../RankingListComponent';
import DataTable from 'react-data-table-component';
import BusinessReviewModal from '../BusinessReviewModal';
import getYearData from '../../services/yearService';

const SentimentDashboard = ({ reviewsData }) => {
  const [yearData, setYearData] = useState([]);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Colors for charts - using consistent color palette with more distinct colors
  const colors = [
    '#2F80ED', // bright blue
    '#27AE60', // emerald green
    '#EB5757', // bright red
    '#9B51E0', // purple
    '#F2994A', // orange
    '#219EBC', // teal
    '#F264A6', // pink
    '#BB6BD9', // violet
    '#2D9CDB', // light blue
    '#219653'  // dark green
  ];
  const [businessColors, setBusinessColors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearDataResponse = await getYearData();
        setYearData(yearDataResponse);
        
        // Combine data using maps_uri as unique identifier
        const combined = reviewsData.map(currentData => {
          const historicalData = yearDataResponse.find(yd => 
            yd.maps_uri === currentData.maps_uri
          );
          
          return {
            ...currentData,
            historicalData: historicalData?.data || null
          };
        });
        
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching year data:", error);
      }
    };

    if (reviewsData?.length > 0) {
      fetchData();
    }
  }, [reviewsData]);

  useEffect(() => {
    // Assign colors to businesses
    const newBusinessColors = {};
    reviewsData.forEach((business, index) => {
      newBusinessColors[business.name] = colors[index % colors.length];
    });
    setBusinessColors(newBusinessColors);
  }, [reviewsData]);

  const getColor = (businessName) => {
    return businessColors[businessName] || colors[0];
  };

  // Sentiment colors remain consistent but updated to be more vibrant
  const SENTIMENT_COLORS = {
    positive: '#27AE60', // emerald green
    neutral: '#F2C94C',  // bright yellow
    negative: '#EB5757'  // bright red
  };

  // Calculate sentiment metrics for each business
  const businessSentimentData = combinedData.map(business => {
    const sentimentBreakdown = business.historicalData?.sentimentBreakdown || {
      positive: 0,
      neutral: 0,
      negative: 0
    };
    
    const total = sentimentBreakdown.positive + sentimentBreakdown.neutral + sentimentBreakdown.negative;
    const sentimentScore = total > 0 
      ? ((sentimentBreakdown.positive + (sentimentBreakdown.neutral * 0.5)) / total * 100)
      : 0;
    
    const positivePercentage = total > 0 
      ? (sentimentBreakdown.positive / total * 100)
      : 0;
    
    return {
      ...business,
      sentimentScore,
      positivePercentage,
      ...sentimentBreakdown,
      total
    };
  });

  // Sort by sentiment score
  const sortedBySentiment = [...businessSentimentData]
    .sort((a, b) => b.sentimentScore - a.sentimentScore);

  const top5Sentiment = sortedBySentiment.slice(0, 5);
  const bottom5Sentiment = sortedBySentiment.slice(-5).reverse();

  // Calculate market-wide metrics
  const totalPositive = sortedBySentiment.reduce((sum, b) => sum + b.positive, 0);
  const totalNeutral = sortedBySentiment.reduce((sum, b) => sum + b.neutral, 0);
  const totalNegative = sortedBySentiment.reduce((sum, b) => sum + b.negative, 0);
  const totalReviews = totalPositive + totalNeutral + totalNegative;
  
  const marketSentimentScore = totalReviews > 0
    ? ((totalPositive + (totalNeutral * 0.5)) / totalReviews * 100)
    : 0;
  
  const avgSentimentScore = sortedBySentiment.reduce((sum, b) => sum + b.sentimentScore, 0) / sortedBySentiment.length;

  // Prepare data for bar chart
  const barChartData = top5Sentiment.map((business, index) => ({
    name: business.name.split(' ').slice(0, 2).join(' '),
    score: business.sentimentScore,
    positive: business.positive,
    neutral: business.neutral,
    negative: business.negative,
    fill: getColor(business.name)
  }));

  // Prepare data for pie chart
  const pieChartData = [
    { name: 'Positive 😊', value: totalPositive, fill: SENTIMENT_COLORS.positive },
    { name: 'Neutral 😐', value: totalNeutral, fill: SENTIMENT_COLORS.neutral },
    { name: 'Negative 😞', value: totalNegative, fill: SENTIMENT_COLORS.negative }
  ];

  // Prepare data table columns
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Sentiment Score',
      selector: row => row.sentimentScore,
      sortable: true,
      right: true,
      format: row => `${row.sentimentScore.toFixed(1)}%`,
    },
    {
      name: 'Positive Reviews',
      selector: row => row.positive,
      sortable: true,
      right: true,
      cell: row => `${row.positive} 😊`,
    },
    {
      name: 'Neutral Reviews',
      selector: row => row.neutral,
      sortable: true,
      right: true,
      cell: row => `${row.neutral} 😐`,
    },
    {
      name: 'Negative Reviews',
      selector: row => row.negative,
      sortable: true,
      right: true,
      cell: row => `${row.negative} 😞`,
    }
  ];

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business);
    setIsModalOpen(true);
  };

  return (
    <Box>
      <Heading size="lg" mb={6}>Customer Sentiment Leaderboard</Heading>

      {/* Top 3 Podium */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        {/* Gold - First Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="lg" textAlign="center"
          transform="scale(1.05)" zIndex={1}
          cursor="pointer"
          onClick={() => top5Sentiment[0] && handleBusinessClick(top5Sentiment[0])}
        >
          <Text fontSize="2xl" mb={2}>🏆</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Sentiment[0]?.name}</Heading>
          <Text fontWeight="bold" fontSize="2xl" color="gray.700">
            {top5Sentiment[0]?.sentimentScore.toFixed(1)}% 😊
          </Text>
          <Text fontSize="sm" color="gray.500">sentiment score</Text>
        </Box>
        {/* Silver - Second Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="sm" textAlign="center" 
          position="relative" top={{ base: 0, md: "20px" }}
          cursor="pointer"
          onClick={() => top5Sentiment[1] && handleBusinessClick(top5Sentiment[1])}
        >
          <Text fontSize="xl" mb={2}>🥈</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Sentiment[1]?.name}</Heading>
          <Text fontWeight="bold" fontSize="xl" color="gray.600">
            {top5Sentiment[1]?.sentimentScore.toFixed(1)}% 😊
          </Text>
          <Text fontSize="sm" color="gray.500">sentiment score</Text>
        </Box>
        {/* Bronze - Third Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="sm" textAlign="center"
          position="relative" top={{ base: 0, md: "20px" }}
          cursor="pointer"
          onClick={() => top5Sentiment[2] && handleBusinessClick(top5Sentiment[2])}
        >
          <Text fontSize="xl" mb={2}>🥉</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Sentiment[2]?.name}</Heading>
          <Text fontWeight="bold" fontSize="xl" color="gray.600">
            {top5Sentiment[2]?.sentimentScore.toFixed(1)}% 😊
          </Text>
          <Text fontSize="sm" color="gray.500">sentiment score</Text>
        </Box>
      </SimpleGrid>

      {/* Market Overview */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Market Sentiment 🎯</StatLabel>
          <StatNumber>{marketSentimentScore.toFixed(1)}%</StatNumber>
          <StatHelpText>Overall sentiment score</StatHelpText>
        </Stat>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Positive Reviews 😊</StatLabel>
          <StatNumber>{((totalPositive / totalReviews) * 100).toFixed(1)}%</StatNumber>
          <StatHelpText>{totalPositive.toLocaleString()} reviews</StatHelpText>
        </Stat>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Review Distribution 🤔</StatLabel>
          <StatNumber>
            😊 {SENTIMENT_COLORS.neutral} 😞
          </StatNumber>
          <StatHelpText>{totalReviews.toLocaleString()} total reviews</StatHelpText>
        </Stat>
      </SimpleGrid>

      {/* Main Content Area with Tabs */}
      <Box bg="white" borderRadius="lg" boxShadow="sm" mb={8}>
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab>Overview</Tab>
            <Tab>Distribution</Tab>
            <Tab>Rankings</Tab>
            <Tab>Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Sentiment Distribution Bar Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Sentiment Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={sortedBySentiment.slice(0, 20)}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name"
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis unit="%" />
                      <Tooltip 
                        formatter={(value) => `${value.toFixed(1)}%`}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <Box bg="white" p={2} border="1px" borderColor="gray.200" borderRadius="md">
                                <Text fontWeight="bold">{payload[0].payload.name}</Text>
                                <Text>Score: {payload[0].value.toFixed(1)}% 🎯</Text>
                                <Text>Positive: {payload[0].payload.positive} 😊</Text>
                                <Text>Neutral: {payload[0].payload.neutral} 😐</Text>
                                <Text>Negative: {payload[0].payload.negative} 😞</Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="sentimentScore" 
                        fill={SENTIMENT_COLORS.positive}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      >
                        {sortedBySentiment.slice(0, 20).map((_, index) => (
                          <Cell key={`cell-${index}`} fill={index < 5 ? SENTIMENT_COLORS.positive : '#CBD5E0'} />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={avgSentimentScore} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Market Sentiment Pie Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Market Sentiment Breakdown</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={({ name, value, percent }) => 
                          `${name} (${(percent * 100).toFixed(1)}%)`
                        }
                        isAnimationActive={true}
                        animationBegin={0}
                        animationDuration={800}
                      />
                      <Tooltip formatter={(value) => value.toLocaleString()} />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Distribution Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {top5Sentiment.map((business, index) => (
                  <Box 
                    key={business.name}
                    p={4}
                    borderWidth={1}
                    borderRadius="lg"
                    cursor="pointer"
                    onClick={() => handleBusinessClick(business)}
                    _hover={{ shadow: "md" }}
                  >
                    <Heading size="sm" mb={4}>
                      {index + 1}. {business.name} {business.sentimentScore > 80 ? '😊' : '🤔'}
                    </Heading>
                    <SimpleGrid columns={3} gap={4} mb={4}>
                      <Box textAlign="center">
                        <Text fontSize="2xl">😊</Text>
                        <Text fontWeight="bold">{business.positive}</Text>
                        <Text fontSize="sm" color="gray.500">Positive</Text>
                      </Box>
                      <Box textAlign="center">
                        <Text fontSize="2xl">😐</Text>
                        <Text fontWeight="bold">{business.neutral}</Text>
                        <Text fontSize="sm" color="gray.500">Neutral</Text>
                      </Box>
                      <Box textAlign="center">
                        <Text fontSize="2xl">😞</Text>
                        <Text fontWeight="bold">{business.negative}</Text>
                        <Text fontSize="sm" color="gray.500">Negative</Text>
                      </Box>
                    </SimpleGrid>
                    <Box height="100px">
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart data={[business]} layout="vertical">
                          <XAxis type="number" domain={[0, 100]} unit="%" />
                          <YAxis type="category" dataKey="name" hide />
                          <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
                          <Bar dataKey="sentimentScore" fill={getColor(business.name)} />
                        </BarChart>
                      </ResponsiveContainer>
                    </Box>
                  </Box>
                ))}
              </SimpleGrid>
            </TabPanel>

            {/* Rankings Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box>
                  <RankingListComponent
                    title="Top Sentiment Rankings 😊"
                    data={top5Sentiment}
                    valueKey="sentimentScore"
                    unit="%"
                    side="top"
                  />
                </Box>
                <Box>
                  <RankingListComponent
                    title="Lowest Sentiment Rankings 💔"
                    data={bottom5Sentiment}
                    valueKey="sentimentScore"
                    unit="%"
                    side="bottom"
                  />
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <DataTable
                columns={columns}
                data={businessSentimentData}
                pagination
                paginationPerPage={10}
                sortable
                striped
                responsive
                highlightOnHover
                pointerOnHover
                onRowClicked={handleBusinessClick}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                theme="default"
                customStyles={{
                  rows: {
                    style: {
                      minHeight: '52px',
                      cursor: 'pointer',
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      fontWeight: 'bold',
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Add the Modal */}
      <BusinessReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        business={selectedBusiness}
        yearData={yearData}
      />
    </Box>
  );
};

export default SentimentDashboard; 