import React, { useState, useEffect } from 'react';
import { Box, Flex, Spinner, useColorModeValue, Text } from '@chakra-ui/react';
import TotalReviewsDashboard from './TotalReviewsDashboard';
import GrossGrowthDashboard from './GrossGrowthDashboard';
import RelativeGrowthDashboard from './RelativeGrowthDashboard';
import SentimentDashboard from './SentimentDashboard';
import WebTrafficDashboard from './WebTrafficDashboard';
import { getReviews } from '../../services/reviewService';

const DashboardWrapper = ({ type }) => {
  const [reviewsData, setReviewsData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setError(null);
      try {
        console.log('Fetching reviews data...');
        const data = await getReviews();
        console.log('Reviews data received:', data);
        if (!data) {
          throw new Error('No data received from the server');
        }
        setReviewsData(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Error fetching reviews data:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const renderDashboard = () => {
    if (error) {
      return (
        <Flex justify="center" align="center" h="full">
          <Text color="red.500">Error loading data: {error}</Text>
        </Flex>
      );
    }

    if (!reviewsData || reviewsData.length === 0) {
      return (
        <Flex justify="center" align="center" h="full">
          <Text>No data available</Text>
        </Flex>
      );
    }

    switch (type) {
      case 'total-reviews':
        return <TotalReviewsDashboard reviewsData={reviewsData} />;
      case 'gross-growth':
        return <GrossGrowthDashboard reviewsData={reviewsData} />;
      case 'relative-growth':
        return <RelativeGrowthDashboard reviewsData={reviewsData} />;
      case 'sentiment':
        return <SentimentDashboard reviewsData={reviewsData} />;
      case 'web-traffic':
        return <WebTrafficDashboard reviewsData={reviewsData} />;
      default:
        return (
          <Flex justify="center" align="center" h="full">
            <Text>Invalid dashboard type</Text>
          </Flex>
        );
    }
  };

  return (
    <Box
      position="relative"
      bg={bgColor}
      borderRadius="lg"
      boxShadow="sm"
      p={6}
      mx="auto"
      maxW="1400px"
      minH="calc(100vh - 2rem)"
      borderColor={borderColor}
      borderWidth="1px"
    >
      {isLoading ? (
        <Flex 
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          justify="center" 
          align="center"
          bg={`${bgColor}dd`}
          borderRadius="lg"
        >
          <Spinner 
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      ) : (
        renderDashboard()
      )}
    </Box>
  );
};

export default DashboardWrapper; 