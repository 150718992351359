import React, { useMemo } from 'react';
import {
  Box,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Tabs,
  TabList,
  TabPanels,
  TabPanel,
  Tab,
  Heading,
  Text,
  Flex,
  Badge,
  Tooltip,
  Icon,
} from '@chakra-ui/react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  Legend,
  CartesianGrid,
  BarChart,
  Bar,
  PieChart,
  Pie,
  Cell,
  ScatterChart,
  Scatter,
  ReferenceLine,
} from 'recharts';
import DataTable from 'react-data-table-component';
import { InfoOutlineIcon } from '@chakra-ui/icons';

const COLORS = [
  '#2F80ED', // bright blue
  '#27AE60', // emerald green
  '#EB5757', // bright red
  '#9B51E0', // purple
  '#F2994A', // orange
  '#219EBC', // teal
  '#F264A6', // pink
  '#BB6BD9', // violet
  '#2D9CDB', // light blue
  '#219653', // dark green
  '#F2C94C', // yellow
  '#6FCF97', // light green
  '#56CCF2', // sky blue
  '#F2994A', // peach
  '#9B51E0', // lavender
  '#EB5757', // coral
  '#2F80ED', // royal blue
  '#27AE60', // forest green
  '#BB6BD9', // orchid
  '#F264A6'  // rose
];

const WebTrafficDashboard = ({ reviewsData }) => {
  // Calculate metrics
  const businessesWithTraffic = useMemo(() => {
    return reviewsData.filter(item => 
      item.traffic_data?.metrics?.estimated_monthly_visitors > 0 && 
      item.name !== "Honky Tonk Highway"
    ).sort((a, b) => 
      (b.traffic_data?.metrics?.estimated_monthly_visitors || 0) - 
      (a.traffic_data?.metrics?.estimated_monthly_visitors || 0)
    );
  }, [reviewsData]);

  const totalMonthlyVisitors = useMemo(() => {
    return businessesWithTraffic.reduce((sum, item) => 
      sum + (item.traffic_data?.metrics?.estimated_monthly_visitors || 0), 0
    );
  }, [businessesWithTraffic]);

  const avgMonthlyVisitors = useMemo(() => {
    return totalMonthlyVisitors / businessesWithTraffic.length;
  }, [totalMonthlyVisitors, businessesWithTraffic]);

  const medianMonthlyVisitors = useMemo(() => {
    const sorted = [...businessesWithTraffic]
      .map(item => item.traffic_data?.metrics?.estimated_monthly_visitors || 0)
      .sort((a, b) => a - b);
    const mid = Math.floor(sorted.length / 2);
    return sorted.length % 2 === 0 
      ? (sorted[mid - 1] + sorted[mid]) / 2 
      : sorted[mid];
  }, [businessesWithTraffic]);

  const top5Traffic = useMemo(() => businessesWithTraffic.slice(0, 5), [businessesWithTraffic]);
  const bottom5Traffic = useMemo(() => [...businessesWithTraffic].reverse().slice(0, 5), [businessesWithTraffic]);

  // Table columns configuration
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
      grow: 2,
    },
    {
      name: 'Monthly Visitors',
      selector: row => row.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      sortable: true,
      format: row => (row.traffic_data?.metrics?.estimated_monthly_visitors || 0).toLocaleString(),
    },
    {
      name: 'Has Social',
      selector: row => {
        const social = row.traffic_data?.metrics?.social || {};
        return Object.values(social).filter(Boolean).length;
      },
      sortable: true,
      cell: row => {
        const social = row.traffic_data?.metrics?.social || {};
        const count = Object.values(social).filter(Boolean).length;
        return (
          <Badge colorScheme={count > 1 ? "green" : count === 1 ? "yellow" : "red"}>
            {count}/3
          </Badge>
        );
      },
    },
    {
      name: 'Status',
      selector: row => row.traffic_data?.metrics?.page_info?.status_code,
      sortable: true,
      cell: row => {
        const status = row.traffic_data?.metrics?.page_info?.status_code;
        return (
          <Badge colorScheme={status === 200 ? "green" : "red"}>
            {status || 'N/A'}
          </Badge>
        );
      },
    }
  ];

  // Prepare traffic distribution data
  const trafficDistributionData = useMemo(() => {
    return businessesWithTraffic.slice(0, 20).map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      visitors: business.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      percentage: ((business.traffic_data?.metrics?.estimated_monthly_visitors || 0) / totalMonthlyVisitors * 100).toFixed(1)
    }));
  }, [businessesWithTraffic, totalMonthlyVisitors]);

  // Prepare pie chart data
  const pieChartData = useMemo(() => {
    return businessesWithTraffic.slice(0, 10).map((business, index) => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      value: business.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      fill: COLORS[index % COLORS.length]
    }));
  }, [businessesWithTraffic]);

  // Prepare XY plot data comparing traffic and growth
  const xyPlotData = useMemo(() => {
    return businessesWithTraffic.map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      x: business.rating_count || 0,
      y: business.traffic_data?.metrics?.estimated_monthly_visitors || 0,
      fill: COLORS[0]
    }));
  }, [businessesWithTraffic]);

  return (
    <Box p={4}>
      <Heading size="lg" mb={6}>Web Traffic Analytics</Heading>
      
      {/* Market Overview Stats */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        <Stat bg="white" p={6} borderRadius="lg" boxShadow="sm" textAlign="center">
          <StatLabel fontSize="lg">Total Monthly Visitors</StatLabel>
          <StatNumber fontSize="3xl" color="blue.500">{totalMonthlyVisitors.toLocaleString()}</StatNumber>
          <StatHelpText fontSize="md">Across {businessesWithTraffic.length} businesses</StatHelpText>
        </Stat>
        <Stat bg="white" p={6} borderRadius="lg" boxShadow="sm" textAlign="center">
          <StatLabel fontSize="lg">Average Monthly Visitors</StatLabel>
          <StatNumber fontSize="3xl" color="green.500">{avgMonthlyVisitors.toFixed(0)}</StatNumber>
          <StatHelpText fontSize="md">Per business</StatHelpText>
        </Stat>
        <Stat bg="white" p={6} borderRadius="lg" boxShadow="sm" textAlign="center">
          <StatLabel fontSize="lg">Median Monthly Visitors</StatLabel>
          <StatNumber fontSize="3xl" color="purple.500">{medianMonthlyVisitors.toLocaleString()}</StatNumber>
          <StatHelpText fontSize="md">Market middle point</StatHelpText>
        </Stat>
      </SimpleGrid>

      {/* Quick Rankings */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4} mb={8}>
        <Box bg="white" p={6} borderRadius="lg" boxShadow="sm">
          <Heading size="md" mb={4} color="blue.600">Top 5 by Traffic</Heading>
          {top5Traffic.map((business, index) => (
            <Flex
              key={business.name}
              justify="space-between"
              align="center"
              p={3}
              borderBottom="1px"
              borderColor="gray.100"
              _hover={{ bg: 'gray.50' }}
            >
              <Text>
                <Text as="span" fontWeight="bold" color={index === 0 ? "yellow.500" : "gray.500"} mr={2}>
                  {index === 0 ? "🏆" : `#${index + 1}`}
                </Text>
                {business.name}
              </Text>
              <Text fontWeight="bold" color="blue.500">
                {(business.traffic_data?.metrics?.estimated_monthly_visitors || 0).toLocaleString()}
              </Text>
            </Flex>
          ))}
        </Box>

        <Box bg="white" p={6} borderRadius="lg" boxShadow="sm">
          <Heading size="md" mb={4} color="blue.600">Social Media Presence</Heading>
          {businessesWithTraffic.slice(0, 5).map((business) => {
            const social = business.traffic_data?.metrics?.social || {};
            const count = Object.values(social).filter(Boolean).length;
            return (
              <Flex
                key={business.name}
                justify="space-between"
                align="center"
                p={3}
                borderBottom="1px"
                borderColor="gray.100"
                _hover={{ bg: 'gray.50' }}
              >
                <Text>{business.name}</Text>
                <Badge colorScheme={count > 1 ? "green" : count === 1 ? "yellow" : "red"}>
                  {count}/3
                </Badge>
              </Flex>
            );
          })}
        </Box>
      </SimpleGrid>

      {/* Main Content Area with Tabs */}
      <Box bg="white" borderRadius="lg" boxShadow="sm">
        <Tabs variant="enclosed-colored">
          <TabList px={4} pt={4}>
            <Tab _selected={{ bg: 'blue.50', color: 'blue.600' }}>Overview</Tab>
            <Tab _selected={{ bg: 'blue.50', color: 'blue.600' }}>Trends</Tab>
            <Tab _selected={{ bg: 'blue.50', color: 'blue.600' }}>Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Traffic Distribution Chart */}
                <Box height="400px" bg="white" p={4} borderRadius="lg">
                  <Heading size="md" mb={4} color="gray.700">Traffic Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={trafficDistributionData}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                      animationDuration={1000}
                      animationBegin={0}
                      animationEasing="ease-out"
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <YAxis tick={{ fill: '#4A5568', fontSize: 12 }} />
                      <RechartsTooltip 
                        formatter={(value) => value.toLocaleString()}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <Box 
                                bg="white" 
                                p={3} 
                                boxShadow="lg" 
                                border="1px" 
                                borderColor="gray.200" 
                                borderRadius="md"
                              >
                                <Text fontWeight="bold">{payload[0].payload.name}</Text>
                                <Text>
                                  {payload[0].value.toLocaleString()} visitors
                                </Text>
                                <Text color="orange.500">
                                  Market Average: {avgMonthlyVisitors.toLocaleString()}
                                </Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="visitors" 
                        fill={COLORS[0]}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      >
                        {trafficDistributionData.map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={index < 5 ? COLORS[index] : '#CBD5E0'} 
                          />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={avgMonthlyVisitors} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                        label={{ 
                          value: 'Market Average', 
                          position: 'right',
                          fill: '#ED8936'
                        }}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Traffic Leaders Pie Chart */}
                <Box height="400px" bg="white" p={4} borderRadius="lg">
                  <Heading size="md" mb={4} color="gray.700">Market Share</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={({ name, value, percent }) => 
                          `${name} (${(percent * 100).toFixed(1)}%)`
                        }
                        labelLine={{ stroke: '#4A5568', strokeWidth: 1 }}
                        isAnimationActive={true}
                        animationBegin={0}
                        animationDuration={800}
                        animationEasing="ease-out"
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <RechartsTooltip 
                        formatter={(value) => `${value.toLocaleString()} visitors`}
                        contentStyle={{
                          backgroundColor: 'white',
                          border: '1px solid #E2E8F0',
                          borderRadius: '4px',
                          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                        }}
                      />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Trends Panel */}
            <TabPanel>
              <Box bg="white" p={6} borderRadius="lg">
                <Heading size="md" mb={2} color="gray.700">Traffic vs Reviews Correlation</Heading>
                <Text color="gray.600" mb={6}>
                  Explore the relationship between review count and monthly web traffic
                </Text>
                <Box height="600px" width="800px" mx="auto">
                  <ResponsiveContainer width="100%" height="100%" aspect={1}>
                    <ScatterChart
                      margin={{ top: 20, right: 40, bottom: 60, left: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" stroke="#E2E8F0" />
                      <XAxis 
                        type="number" 
                        dataKey="x" 
                        name="Total Reviews" 
                        unit=" reviews"
                        label={{ 
                          value: 'Total Reviews', 
                          position: 'bottom', 
                          offset: 40,
                          fill: '#4A5568'
                        }}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <YAxis 
                        type="number" 
                        dataKey="y" 
                        name="Monthly Visitors" 
                        unit=" visitors"
                        label={{ 
                          value: 'Monthly Visitors', 
                          angle: -90, 
                          position: 'left', 
                          offset: 40,
                          fill: '#4A5568'
                        }}
                        tick={{ fill: '#4A5568', fontSize: 12 }}
                      />
                      <RechartsTooltip 
                        cursor={{ strokeDasharray: '3 3' }}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            const data = payload[0].payload;
                            return (
                              <Box 
                                bg="white" 
                                p={3} 
                                boxShadow="lg" 
                                border="1px" 
                                borderColor="gray.200" 
                                borderRadius="md"
                              >
                                <Text fontWeight="bold">{data.name}</Text>
                                <Text>Reviews: {data.x.toLocaleString()}</Text>
                                <Text>Monthly Visitors: {data.y.toLocaleString()}</Text>
                              </Box>
                            );
                          }
                          return null;
                        }}
                      />
                      <Scatter 
                        name="Businesses" 
                        data={xyPlotData} 
                        fill={COLORS[0]}
                      >
                        {xyPlotData.map((entry, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={COLORS[index % COLORS.length]} 
                            fillOpacity={0.8}
                            stroke={COLORS[index % COLORS.length]}
                            strokeWidth={1}
                          />
                        ))}
                      </Scatter>
                    </ScatterChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <Box bg="white" p={6} borderRadius="lg">
                <Heading size="md" mb={4} color="gray.700">Detailed Business Data</Heading>
                <DataTable
                  columns={columns}
                  data={businessesWithTraffic}
                  pagination
                  paginationPerPage={10}
                  sortable
                  striped
                  responsive
                  highlightOnHover
                  pointerOnHover
                  defaultSortFieldId={1}
                  defaultSortAsc={false}
                  theme="default"
                  customStyles={{
                    rows: {
                      style: {
                        minHeight: '52px',
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#EBF8FF',
                        },
                      },
                    },
                    headCells: {
                      style: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                        fontWeight: 'bold',
                        color: '#2D3748',
                      },
                    },
                    cells: {
                      style: {
                        paddingLeft: '16px',
                        paddingRight: '16px',
                      },
                    },
                  }}
                />
              </Box>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </Box>
  );
};

export default WebTrafficDashboard; 