import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
  Flex,
  Icon,
  Link,
  Text,
  VStack,
  HStack,
  Tag,
  TagLabel,
  Wrap,
  WrapItem,
  SimpleGrid,
  Heading,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  StatArrow,
} from '@chakra-ui/react';
import { StarIcon, ExternalLinkIcon, PhoneIcon } from '@chakra-ui/icons';
import { FaMapMarkerAlt, FaGlobe, FaChartLine, FaSmile, FaMeh, FaFrown } from 'react-icons/fa';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend, LineChart, Line } from 'recharts';

// Emoji mapping for business types
const typeEmojis = {
  restaurant: '🍽️',
  wine_bar: '🍷',
  vegan_restaurant: '🌱',
  vegetarian_restaurant: '🥗',
  bar: '🍸',
  food: '🍴',
  cafe: '☕',
  market: '🏪',
  deli: '🥪',
  asian_restaurant: '🥢',
  thai_restaurant: '🇹🇭',
  indian_restaurant: '🍛',
  mediterranean_restaurant: '🫒',
  american_restaurant: '🍔',
  fine_dining_restaurant: '✨',
  brunch_restaurant: '🍳',
  middle_eastern_restaurant: '🧆',
  chinese_restaurant: '🥡',
  meal_takeaway: '📦',
  food_store: '🏪',
  catering_service: '👨‍🍳',
  food_delivery: '🚚',
};

const BusinessReviewModal = ({ isOpen, onClose, business, yearData }) => {
  if (!business) return null;

  const historicalData = business.historicalData || {};
  const sentimentBreakdown = historicalData.sentimentBreakdown || {
    positive: 0,
    neutral: 0,
    negative: 0
  };
  const totalSentiment = sentimentBreakdown.positive + sentimentBreakdown.neutral + sentimentBreakdown.negative;
  const positivePercentage = totalSentiment > 0 
    ? ((sentimentBreakdown.positive / totalSentiment) * 100).toFixed(1)
    : 0;
  
  // Prepare monthly data
  const monthlyData = Object.entries(historicalData.monthlyReviewCounts || {})
    .map(([month, count]) => ({
      month: new Date(month).toLocaleDateString('en-US', { month: 'short', year: 'numeric' }),
      count,
      ...historicalData.monthlyRatings?.[month],
    }))
    .sort((a, b) => new Date(a.month) - new Date(b.month));

  // Calculate three month growth
  const months = Object.keys(historicalData.monthlyReviewCounts || {}).sort();
  const lastThreeMonths = months.slice(-3);
  const threeMonthGrowth = lastThreeMonths.reduce((sum, month) => 
    sum + (historicalData.monthlyReviewCounts?.[month] || 0), 0
  );
  const monthlyGrowthRate = threeMonthGrowth / 3; // Average monthly growth rate

  // Calculate comparison to previous period
  const previousThreeMonths = months.slice(-6, -3);
  const previousPeriodGrowth = previousThreeMonths.reduce((sum, month) => 
    sum + (historicalData.monthlyReviewCounts?.[month] || 0), 0
  );
  const growthComparison = threeMonthGrowth - previousPeriodGrowth;

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent maxW="90vw" maxH="90vh" mx="auto" my="5vh">
        <Box h="full" display="flex" flexDirection="column">
          <ModalHeader borderBottom="1px" borderColor="gray.200" flex="0 0 auto">
            <VStack align="stretch" spacing={3}>
              <Flex justify="space-between" align="center">
                <Text fontSize="2xl" fontWeight="bold">{business.name}</Text>
                <HStack spacing={4}>
                  {business.rating_avg && (
                    <Flex align="center">
                      <Text fontSize="xl" fontWeight="bold" mr={1}>
                        {business.rating_avg.toFixed(1)}
                      </Text>
                      <Icon as={StarIcon} color="yellow.400" boxSize={5} />
                      <Text fontSize="sm" color="gray.600" ml={1}>
                        ({business.rating_count?.toLocaleString()} reviews)
                      </Text>
                    </Flex>
                  )}
                </HStack>
              </Flex>

              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                <VStack align="start" spacing={2}>
                  {business.address && (
                    <HStack>
                      <Icon as={FaMapMarkerAlt} color="red.500" />
                      <Link href={business.maps_uri} isExternal color="blue.500">
                        {business.address} <ExternalLinkIcon mx="2px" />
                      </Link>
                    </HStack>
                  )}
                  {business.phone && (
                    <HStack>
                      <Icon as={PhoneIcon} color="green.500" />
                      <Link href={`tel:${business.phone}`} color="blue.500">
                        {business.phone}
                      </Link>
                    </HStack>
                  )}
                  {business.website_uri && (
                    <HStack>
                      <Icon as={FaGlobe} color="blue.500" />
                      <Link href={business.website_uri} isExternal color="blue.500">
                        Visit Website <ExternalLinkIcon mx="2px" />
                      </Link>
                    </HStack>
                  )}
                </VStack>

                <Box>
                  <Wrap spacing={2}>
                    {business.types?.map((type) => (
                      <WrapItem key={type}>
                        <Tag size="md" borderRadius="full" variant="subtle" colorScheme="blue">
                          <TagLabel>
                            {typeEmojis[type] || '🏢'} {type.split('_').map(word => 
                              word.charAt(0).toUpperCase() + word.slice(1)
                            ).join(' ')}
                          </TagLabel>
                        </Tag>
                      </WrapItem>
                    ))}
                  </Wrap>
                </Box>
              </SimpleGrid>

              {/* Quick Stats */}
              <SimpleGrid columns={{ base: 2, md: 4 }} spacing={4} mt={2}>
                <Stat size="sm">
                  <StatLabel>Monthly Growth 📈</StatLabel>
                  <StatNumber fontSize="lg">
                    {monthlyGrowthRate.toFixed(1)}
                  </StatNumber>
                  <StatHelpText>
                    <StatArrow 
                      type={growthComparison > 0 ? 'increase' : 'decrease'} 
                    />
                    {Math.abs(growthComparison)}
                  </StatHelpText>
                </Stat>

                <Stat size="sm">
                  <StatLabel>Sentiment Score 🎯</StatLabel>
                  <StatNumber fontSize="lg">{positivePercentage}%</StatNumber>
                  <StatHelpText>positive reviews</StatHelpText>
                </Stat>

                <Stat size="sm">
                  <StatLabel>Sentiment Split 📊</StatLabel>
                  <HStack spacing={3} mt={1}>
                    <HStack>
                      <Icon as={FaSmile} color="green.500" />
                      <Text fontSize="sm">{sentimentBreakdown.positive}</Text>
                    </HStack>
                    <HStack>
                      <Icon as={FaMeh} color="yellow.500" />
                      <Text fontSize="sm">{sentimentBreakdown.neutral}</Text>
                    </HStack>
                    <HStack>
                      <Icon as={FaFrown} color="red.500" />
                      <Text fontSize="sm">{sentimentBreakdown.negative}</Text>
                    </HStack>
                  </HStack>
                </Stat>

                <Stat size="sm">
                  <StatLabel>Keywords 🔍</StatLabel>
                  <Text fontSize="sm" noOfLines={2} mt={1}>
                    {(historicalData.commonKeywords || []).slice(0, 3).join(', ')}
                  </Text>
                </Stat>
              </SimpleGrid>
            </VStack>
          </ModalHeader>
          <ModalCloseButton />
          
          <Box flex="1 1 auto" overflowY="auto" p={6}>
            <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8} maxW="1200px" mx="auto">
              {/* Monthly Review Counts */}
              <Box>
                <Heading size="md" mb={4}>Monthly Reviews</Heading>
                <Box h="250px" maxW="500px">
                  <ResponsiveContainer width="100%" height="100%">
                    <LineChart data={monthlyData} margin={{ top: 5, right: 20, bottom: 20, left: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="month" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Line 
                        type="monotone" 
                        dataKey="count" 
                        stroke="#3182CE" 
                        strokeWidth={2}
                        name="Reviews"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      />
                    </LineChart>
                  </ResponsiveContainer>
                </Box>
              </Box>

              {/* Monthly Ratings Breakdown */}
              <Box>
                <Heading size="md" mb={4}>Monthly Ratings Breakdown</Heading>
                <Box h="250px" maxW="500px">
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart data={monthlyData} margin={{ top: 5, right: 20, bottom: 20, left: 0 }}>
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="month" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                        tick={{ fontSize: 12 }}
                      />
                      <YAxis />
                      <Tooltip />
                      <Legend />
                      <Bar 
                        dataKey="positive" 
                        stackId="a" 
                        fill="#48BB78" 
                        name="Positive"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      />
                      <Bar 
                        dataKey="neutral" 
                        stackId="a" 
                        fill="#ECC94B" 
                        name="Neutral"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      />
                      <Bar 
                        dataKey="negative" 
                        stackId="a" 
                        fill="#F56565" 
                        name="Negative"
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={0}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </Box>
            </SimpleGrid>
          </Box>
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default BusinessReviewModal; 