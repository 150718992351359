import React, { useState, useEffect } from 'react';
import { Box, Heading, Text, SimpleGrid, Stat, StatLabel, StatNumber, StatHelpText, Flex, Spinner, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';
import { BarChart, Bar, PieChart, Pie, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Cell, LineChart, Line, Legend, ReferenceLine } from 'recharts';
import RankingListComponent from '../RankingListComponent';
import DataTable from 'react-data-table-component';
import BusinessReviewModal from '../BusinessReviewModal';
import getYearData from '../../services/yearService';

const TotalReviewsDashboard = ({ reviewsData }) => {
  const [yearData, setYearData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [combinedData, setCombinedData] = useState([]);
  const [selectedBusiness, setSelectedBusiness] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Colors for charts - using consistent color palette with more distinct colors
  const colors = [
    '#2F80ED', // bright blue
    '#27AE60', // emerald green
    '#EB5757', // bright red
    '#9B51E0', // purple
    '#F2994A', // orange
    '#219EBC', // teal
    '#F264A6', // pink
    '#BB6BD9', // violet
    '#2D9CDB', // light blue
    '#219653', // dark green
    '#F2C94C', // yellow
    '#6FCF97', // light green
    '#56CCF2', // sky blue
    '#F2994A', // peach
    '#9B51E0', // lavender
    '#EB5757', // coral
    '#2F80ED', // royal blue
    '#27AE60', // forest green
    '#BB6BD9', // orchid
    '#F264A6'  // rose
  ];
  const [businessColors, setBusinessColors] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const yearDataResponse = await getYearData();
        setYearData(yearDataResponse);
        
        // Combine data using maps_uri as unique identifier
        const combined = reviewsData.map(currentData => {
          const historicalData = yearDataResponse.find(yd => 
            yd.maps_uri === currentData.maps_uri
          );
          
          return {
            ...currentData,
            historicalData: historicalData?.data || null
          };
        });
        
        setCombinedData(combined);
      } catch (error) {
        console.error("Error fetching year data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (reviewsData?.length > 0) {
      fetchData();
    }
  }, [reviewsData]);

  useEffect(() => {
    // Assign colors to businesses
    const newBusinessColors = {};
    reviewsData.forEach((business, index) => {
      newBusinessColors[business.name] = colors[index % colors.length];
    });
    setBusinessColors(newBusinessColors);
  }, [reviewsData]);

  const getColor = (businessName) => {
    return businessColors[businessName] || colors[0];
  };

  if (isLoading) {
    return (
      <Flex justify="center" align="center" h="200px">
        <Spinner />
      </Flex>
    );
  }

  // Sort businesses by total reviews
  const sortedByTotal = [...combinedData].sort((a, b) => b.rating_count - a.rating_count);
  const top5Total = sortedByTotal.slice(0, 5);
  const bottom5Total = [...sortedByTotal].reverse().slice(0, 5);

  // Calculate market-wide metrics
  const totalMarketReviews = sortedByTotal.reduce((sum, b) => sum + b.rating_count, 0);
  const avgReviewsPerBusiness = totalMarketReviews / sortedByTotal.length;
  const medianReviews = sortedByTotal[Math.floor(sortedByTotal.length / 2)].rating_count;

  // Prepare data for bar chart
  const barChartData = top5Total.map(business => ({
    name: business.name.split(' ').slice(0, 2).join(' '),
    reviews: business.rating_count,
    fill: getColor(business.name)
  }));

  // Prepare data for pie chart
  const pieChartData = sortedByTotal.slice(0, 20).map(business => ({
    name: business.name.split(' ').slice(0, 2).join(' '),
    value: business.rating_count,
    fill: getColor(business.name)
  }));

  // Prepare data for line chart
  const monthlyTrendData = [];
  const marketMonthlyData = {};
  
  sortedByTotal.forEach(business => {
    if (business.historicalData?.monthlyReviewCounts) {
      Object.entries(business.historicalData.monthlyReviewCounts).forEach(([month, count]) => {
        marketMonthlyData[month] = (marketMonthlyData[month] || 0) + count;
      });
    }
  });

  // Add market average and top 5 to trend data
  Object.entries(marketMonthlyData)
    .sort((a, b) => new Date(a[0]) - new Date(b[0]))
    .forEach(([month, total]) => {
      const dataPoint = {
        month,
        'Market Average': Math.round(total / sortedByTotal.length),
        ...top5Total.reduce((acc, business) => ({
          ...acc,
          [business.name.split(' ').slice(0, 2).join(' ')]: 
            business.historicalData?.monthlyReviewCounts?.[month] || 0
        }), {})
      };
      monthlyTrendData.push(dataPoint);
    });

  // Prepare data for the table
  const columns = [
    {
      name: 'Business Name',
      selector: row => row.name,
      sortable: true,
    },
    {
      name: 'Total Reviews',
      selector: row => row.rating_count,
      sortable: true,
      right: true,
    },
    {
      name: 'Rating',
      selector: row => row.rating_avg,
      sortable: true,
      right: true,
      format: row => row.rating_avg.toFixed(1),
    }
  ];

  const handleBusinessClick = (business) => {
    setSelectedBusiness(business);
    setIsModalOpen(true);
  };

  // Add click handlers to various components
  const customBarProps = {
    onClick: (data) => {
      const business = sortedByTotal.find(b => 
        b.name.split(' ').slice(0, 2).join(' ') === data.payload.name
      );
      if (business) handleBusinessClick(business);
    },
    style: { cursor: 'pointer' }
  };

  const customPieProps = {
    onClick: (data) => {
      const business = sortedByTotal.find(b => 
        b.name.split(' ').slice(0, 2).join(' ') === data.name
      );
      if (business) handleBusinessClick(business);
    },
    style: { cursor: 'pointer' }
  };

  // Modify the data table to handle row clicks
  const handleRowClick = (row) => {
    handleBusinessClick(row);
  };

  return (
    <Box>
      <Heading size="lg" mb={6}>Total Reviews Leaderboard</Heading>

      {/* Top 3 Podium */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        {/* Gold - First Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="lg" textAlign="center"
          transform="scale(1.05)" zIndex={1}
          cursor="pointer"
          onClick={() => top5Total[0] && handleBusinessClick(top5Total[0])}
        >
          <Text fontSize="2xl" mb={2}>🏆</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Total[0]?.name}</Heading>
          <Text fontWeight="bold" fontSize="2xl" color="gray.700">
            {top5Total[0]?.rating_count.toLocaleString()}
          </Text>
          <Text fontSize="sm" color="gray.500">reviews</Text>
        </Box>
        {/* Silver - Second Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="sm" textAlign="center" 
          position="relative" top={{ base: 0, md: "20px" }}
          cursor="pointer"
          onClick={() => top5Total[1] && handleBusinessClick(top5Total[1])}
        >
          <Text fontSize="xl" mb={2}>🥈</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Total[1]?.name}</Heading>
          <Text fontWeight="bold" fontSize="xl" color="gray.600">
            {top5Total[1]?.rating_count.toLocaleString()}
          </Text>
          <Text fontSize="sm" color="gray.500">reviews</Text>
        </Box>
        {/* Bronze - Third Place */}
        <Box 
          bg="white" p={4} borderRadius="lg" boxShadow="sm" textAlign="center"
          position="relative" top={{ base: 0, md: "20px" }}
          cursor="pointer"
          onClick={() => top5Total[2] && handleBusinessClick(top5Total[2])}
        >
          <Text fontSize="xl" mb={2}>🥉</Text>
          <Heading size="md" mb={2} noOfLines={1}>{top5Total[2]?.name}</Heading>
          <Text fontWeight="bold" fontSize="xl" color="gray.600">
            {top5Total[2]?.rating_count.toLocaleString()}
          </Text>
          <Text fontSize="sm" color="gray.500">reviews</Text>
        </Box>
      </SimpleGrid>
      
      {/* Market Overview - Always visible */}
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4} mb={8}>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Total Market Reviews</StatLabel>
          <StatNumber>{totalMarketReviews.toLocaleString()}</StatNumber>
          <StatHelpText>Across {sortedByTotal.length} businesses</StatHelpText>
        </Stat>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Average Reviews</StatLabel>
          <StatNumber>{avgReviewsPerBusiness.toFixed(1)}</StatNumber>
          <StatHelpText>Per business</StatHelpText>
        </Stat>
        <Stat bg="white" p={4} borderRadius="lg" boxShadow="sm">
          <StatLabel>Median Reviews</StatLabel>
          <StatNumber>{medianReviews}</StatNumber>
          <StatHelpText>Market middle point</StatHelpText>
        </Stat>
      </SimpleGrid>

      {/* Main Content Area with Tabs */}
      <Box bg="white" borderRadius="lg" boxShadow="sm" mb={8}>
        <Tabs>
          <TabList px={4} pt={4}>
            <Tab>Overview</Tab>
            <Tab>Trends</Tab>
            <Tab>Rankings</Tab>
            <Tab>Details</Tab>
          </TabList>

          <TabPanels>
            {/* Overview Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={8}>
                {/* Distribution Bar Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Review Distribution</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart 
                      data={sortedByTotal.slice(0, 20).map(business => ({
                        name: business.name.split(' ').slice(0, 2).join(' '),
                        reviews: business.rating_count
                      }))}
                      margin={{ top: 5, right: 30, left: 20, bottom: 60 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis 
                        dataKey="name" 
                        angle={-45}
                        textAnchor="end"
                        height={60}
                        interval={0}
                      />
                      <YAxis />
                      <Tooltip 
                        formatter={(value) => value.toLocaleString()}
                        content={({ active, payload }) => {
                          if (active && payload && payload.length) {
                            return (
                              <div style={{ backgroundColor: 'white', padding: '10px', border: '1px solid #ccc' }}>
                                <p>{`${payload[0].payload.name}: ${payload[0].value.toLocaleString()}`}</p>
                                <p style={{ color: '#ED8936' }}>{`Market Average: ${avgReviewsPerBusiness.toLocaleString()}`}</p>
                              </div>
                            );
                          }
                          return null;
                        }}
                      />
                      <Bar 
                        dataKey="reviews" 
                        fill={colors[0]} 
                        {...customBarProps}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      >
                        {sortedByTotal.slice(0, 20).map((business, index) => (
                          <Cell 
                            key={`cell-${index}`} 
                            fill={index < 5 ? getColor(business.name) : '#CBD5E0'} 
                          />
                        ))}
                      </Bar>
                      <ReferenceLine 
                        y={avgReviewsPerBusiness} 
                        stroke="#ED8936" 
                        strokeDasharray="5 5"
                        strokeWidth={2}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                </Box>

                {/* Market Share Pie Chart */}
                <Box height="400px">
                  <Heading size="md" mb={4}>Market Share</Heading>
                  <ResponsiveContainer width="100%" height="100%">
                    <PieChart>
                      <Pie
                        data={pieChartData}
                        cx="50%"
                        cy="50%"
                        innerRadius={60}
                        outerRadius={120}
                        paddingAngle={2}
                        dataKey="value"
                        label={(entry) => `${entry.name} (${((entry.value / totalMarketReviews) * 100).toFixed(1)}%)`}
                        {...customPieProps}
                      >
                        {pieChartData.map((entry, index) => (
                          <Cell key={`cell-${index}`} fill={entry.fill} />
                        ))}
                      </Pie>
                      <Tooltip formatter={(value) => `${value.toLocaleString()} reviews`} />
                    </PieChart>
                  </ResponsiveContainer>
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Trends Panel */}
            <TabPanel>
              <Box height="500px">
                <Heading size="md" mb={4}>Monthly Review Trends</Heading>
                <ResponsiveContainer width="100%" height="100%">
                  <LineChart data={monthlyTrendData}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="month" 
                      tickFormatter={(value) => value.split('-')[1]}
                    />
                    <YAxis />
                    <Tooltip 
                      labelFormatter={(value) => new Date(value + '-01').toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}
                      formatter={(value) => value.toLocaleString()}
                    />
                    <Legend />
                    <Line
                      type="monotone"
                      dataKey="Market Average"
                      stroke="#ED8936"
                      strokeWidth={3}
                      strokeDasharray="5 5"
                      isAnimationActive={true}
                      animationDuration={1000}
                      animationBegin={300}
                      animationEasing="ease-out"
                    />
                    {top5Total.map((business, index) => (
                      <Line
                        key={business.name}
                        type="monotone"
                        dataKey={business.name.split(' ').slice(0, 2).join(' ')}
                        stroke={getColor(business.name)}
                        strokeWidth={2}
                        isAnimationActive={true}
                        animationDuration={1000}
                        animationBegin={300}
                        animationEasing="ease-out"
                      />
                    ))}
                  </LineChart>
                </ResponsiveContainer>
              </Box>
            </TabPanel>

            {/* Rankings Panel */}
            <TabPanel>
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                <Box>
                  <RankingListComponent 
                    side="top" 
                    title="👍 Most Reviews" 
                    data={top5Total} 
                    valueKey="rating_count" 
                    unit="reviews" 
                  />
                </Box>
                <Box>
                  <RankingListComponent 
                    side="bottom" 
                    title=" Least Reviews" 
                    data={bottom5Total} 
                    valueKey="rating_count" 
                    unit="reviews" 
                  />
                </Box>
              </SimpleGrid>
            </TabPanel>

            {/* Details Panel */}
            <TabPanel>
              <DataTable
                columns={columns}
                data={reviewsData}
                pagination
                paginationPerPage={10}
                sortable
                striped
                responsive
                highlightOnHover
                pointerOnHover
                onRowClicked={handleRowClick}
                defaultSortFieldId={2}
                defaultSortAsc={false}
                theme="default"
                customStyles={{
                  rows: {
                    style: {
                      minHeight: '52px',
                      cursor: 'pointer',
                    },
                  },
                  headCells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                      fontWeight: 'bold',
                    },
                  },
                  cells: {
                    style: {
                      paddingLeft: '16px',
                      paddingRight: '16px',
                    },
                  },
                }}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>

      {/* Add the Modal */}
      <BusinessReviewModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        business={selectedBusiness}
        yearData={yearData}
      />
    </Box>
  );
};

export default TotalReviewsDashboard; 