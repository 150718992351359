import React, { useState, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  VStack,
  HStack,
  Button,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  Legend,
} from 'recharts';
import { FaChartLine, FaChartBar, FaChartPie, FaSmile } from 'react-icons/fa';
import getYearData from '../services/yearService';
import { getReviews } from '../services/reviewService';

const HomePage = () => {
  const [reviewsData, setReviewsData] = useState([]);
  const [yearData, setYearData] = useState([]);
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  // Emojis for different sections
  const EMOJIS = {
    reviews: '📊',
    growth: '📈',
    relative: '🚀',
    sentiment: '😊',
    star: '⭐',
    fire: '🔥',
    trophy: '🏆',
    rocket: '🚀',
    chart: '📈',
    target: '🎯',
    sparkles: '✨',
    crown: '👑'
  };

  // Colors for charts
  const COLORS = ['#48BB78', '#4299E1', '#F6AD55', '#9F7AEA', '#F56565'];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [reviewsResponse, yearResponse] = await Promise.all([
          getReviews(),
          getYearData()
        ]);
        setReviewsData(reviewsResponse);
        setYearData(yearResponse);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Calculate key metrics
  const calculateMetrics = () => {
    if (!reviewsData.length) return null;

    // Sort businesses by total reviews
    const sortedByTotal = [...reviewsData].sort((a, b) => b.rating_count - a.rating_count);
    const totalReviews = sortedByTotal.reduce((sum, b) => sum + b.rating_count, 0);
    const avgRating = sortedByTotal.reduce((sum, b) => sum + b.rating_avg, 0) / sortedByTotal.length;
    
    // Calculate market activity
    const allMonthlyData = {};
    const businessGrowthData = reviewsData.map(business => {
      const yearInfo = yearData.find(yd => yd.maps_uri === business.maps_uri);
      const monthlyData = yearInfo?.data?.monthlyReviewCounts || {};
      const months = Object.keys(monthlyData).sort();
      
      months.forEach(month => {
        allMonthlyData[month] = (allMonthlyData[month] || 0) + (monthlyData[month] || 0);
      });
      
      // Calculate recent activity
      const lastThreeMonths = months.slice(-3);
      const recentActivity = lastThreeMonths.reduce((sum, month) => sum + (monthlyData[month] || 0), 0);
      
      return {
        name: business.name,
        activity: recentActivity,
        monthlyData
      };
    });

    // Market activity trends
    const monthlyTrends = Object.entries(allMonthlyData)
      .sort((a, b) => new Date(a[0]) - new Date(b[0]))
      .map(([month, count]) => ({
        month: new Date(month).toLocaleDateString('en-US', { month: 'short' }),
        count,
        date: month
      }));

    // Calculate market momentum (last 3 months vs previous 3 months)
    const last6Months = monthlyTrends.slice(-6);
    const last3MonthsActivity = last6Months.slice(-3).reduce((sum, m) => sum + m.count, 0);
    const previous3MonthsActivity = last6Months.slice(0, 3).reduce((sum, m) => sum + m.count, 0);
    const marketMomentum = previous3MonthsActivity > 0 ? 
      ((last3MonthsActivity - previous3MonthsActivity) / previous3MonthsActivity * 100) : 0;

    // Calculate market competition (standard deviation of review counts)
    const avgReviews = totalReviews / reviewsData.length;
    const variance = reviewsData.reduce((sum, b) => sum + Math.pow(b.rating_count - avgReviews, 2), 0) / reviewsData.length;
    const competitionScore = Math.sqrt(variance) / avgReviews * 100;

    // Sentiment analysis
    const sentimentData = yearData.map(business => {
      const sentimentBreakdown = business.data?.sentimentBreakdown || {
        positive: 0,
        neutral: 0,
        negative: 0
      };
      const total = sentimentBreakdown.positive + sentimentBreakdown.neutral + sentimentBreakdown.negative;
      return {
        name: business.name,
        score: total > 0 ? ((sentimentBreakdown.positive + (sentimentBreakdown.neutral * 0.5)) / total * 100) : 0,
        total,
        positive: sentimentBreakdown.positive,
        neutral: sentimentBreakdown.neutral,
        negative: sentimentBreakdown.negative
      };
    }).sort((a, b) => b.score - a.score);

    const avgSentiment = sentimentData.reduce((sum, business) => sum + business.score, 0) / sentimentData.length;

    // Market leaders
    const marketLeaders = sortedByTotal.slice(0, 10).map(business => ({
      name: business.name,
      reviews: business.rating_count,
      rating: business.rating_avg,
      share: (business.rating_count / totalReviews * 100).toFixed(1)
    }));

    // Calculate type distribution
    const allTypes = new Set();
    const typeCount = {};
    reviewsData.forEach(business => {
      (business.types || []).forEach(type => {
        if (!type.includes('point_of_interest') && !type.includes('establishment') && !type.includes('food')) {
          allTypes.add(type);
          typeCount[type] = (typeCount[type] || 0) + 1;
        }
      });
    });
    
    const typeDistribution = Object.entries(typeCount)
      .map(([type, count]) => ({
        name: type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        value: (count / reviewsData.length * 100).toFixed(1),
        count
      }))
      .sort((a, b) => b.count - a.count);

    // Calculate primary type distribution
    const primaryTypes = {};
    reviewsData.forEach(business => {
      const type = business.primary_type || 'other';
      primaryTypes[type] = (primaryTypes[type] || 0) + 1;
    });
    
    const primaryTypeDistribution = Object.entries(primaryTypes)
      .map(([type, count]) => ({
        name: type.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' '),
        value: (count / reviewsData.length * 100).toFixed(1)
      }))
      .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

    // Prepare visualization data
    const activityTrendData = monthlyTrends.slice(-6);
    const competitorData = sortedByTotal.map(business => ({
      name: business.name.split(' ').slice(0, 2).join(' '),
      reviews: business.rating_count,
      rating: business.rating_avg.toFixed(1)
    }));

    const sentimentOverview = {
      positive: sentimentData.reduce((sum, b) => sum + b.positive, 0),
      neutral: sentimentData.reduce((sum, b) => sum + b.neutral, 0),
      negative: sentimentData.reduce((sum, b) => sum + b.negative, 0)
    };
    const totalSentiment = sentimentOverview.positive + sentimentOverview.neutral + sentimentOverview.negative;
    const sentimentDistribution = {
      positive: ((sentimentOverview.positive / totalSentiment) * 100).toFixed(1),
      neutral: ((sentimentOverview.neutral / totalSentiment) * 100).toFixed(1),
      negative: ((sentimentOverview.negative / totalSentiment) * 100).toFixed(1)
    };

    // Calculate response rates
    const responseData = yearData.map(business => {
      const responseCount = business.data?.responseCount || 0;
      const totalReviews = business.data?.monthlyReviewCounts ? 
        Object.values(business.data.monthlyReviewCounts).reduce((sum, count) => sum + count, 0) : 0;
      return {
        name: business.name,
        responseRate: totalReviews > 0 ? (responseCount / totalReviews * 100) : 0
      };
    });
    const avgResponseRate = responseData.reduce((sum, b) => sum + b.responseRate, 0) / responseData.length;
    const topResponders = responseData
      .sort((a, b) => b.responseRate - a.responseRate)
      .slice(0, 3);

    // Calculate recent trends (last month vs previous month)
    const recentTrends = reviewsData.map(business => {
      const yearInfo = yearData.find(yd => yd.maps_uri === business.maps_uri);
      const monthlyData = yearInfo?.data?.monthlyReviewCounts || {};
      const months = Object.keys(monthlyData).sort();
      const lastMonth = monthlyData[months[months.length - 1]] || 0;
      const previousMonth = monthlyData[months[months.length - 2]] || 0;
      const trend = previousMonth > 0 ? ((lastMonth - previousMonth) / previousMonth * 100) : 0;
      
      return {
        name: business.name,
        trend,
        lastMonth,
        previousMonth
      };
    }).sort((a, b) => b.trend - a.trend);

    // Calculate category distribution
    const categories = {};
    reviewsData.forEach(business => {
      const category = business.category || 'Uncategorized';
      categories[category] = (categories[category] || 0) + 1;
    });
    
    const categoryDistribution = Object.entries(categories)
      .map(([category, count]) => ({
        name: category,
        value: (count / reviewsData.length * 100).toFixed(1)
      }))
      .sort((a, b) => parseFloat(b.value) - parseFloat(a.value));

    return {
      totalReviews,
      avgRating,
      marketMomentum,
      competitionScore,
      avgSentiment,
      marketLeaders,
      activityTrendData,
      competitorData,
      sentimentDistribution,
      businessCount: reviewsData.length,
      typeDistribution: typeDistribution.slice(0, 10),
      primaryTypeDistribution,
      recentTrends: recentTrends.slice(0, 8)
    };
  };

  const metrics = calculateMetrics();

  if (!metrics) return null;

  return (
    <Box maxW="1400px" mx="auto" p={4}>
      <VStack spacing={6} align="stretch">
        {/* Header Section */}
        <Flex justify="space-between" align="center">
          <Box>
            <Heading size="lg">Market Overview {EMOJIS.sparkles}</Heading>
            <Text color="gray.600" mt={1}>
              Analyzing {metrics.businessCount} businesses in your local market
            </Text>
          </Box>
          <HStack spacing={2}>
            <Button
              size="sm"
              colorScheme="blue"
              variant="outline"
              onClick={() => navigate('/dashboards/total-reviews')}
              leftIcon={<Icon as={FaChartBar} />}
            >
              Details
            </Button>
          </HStack>
        </Flex>

        {/* Market Summary */}
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Heading size="md" mb={4}>Market Health {EMOJIS.chart}</Heading>
            <SimpleGrid columns={{ base: 2, lg: 3 }} spacing={4}>
              <Box>
                <Text fontSize="sm" color="gray.500">Market Size</Text>
                <Stat>
                  <StatNumber fontSize="2xl">{metrics.totalReviews.toLocaleString()}</StatNumber>
                  <StatHelpText>Total Reviews</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Market Momentum</Text>
                <Stat>
                  <StatNumber fontSize="2xl" color={metrics.marketMomentum >= 0 ? "green.500" : "red.500"}>
                    {metrics.marketMomentum >= 0 ? "+" : ""}{metrics.marketMomentum.toFixed(1)}%
                  </StatNumber>
                  <StatHelpText>Quarter over Quarter</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Competition Level</Text>
                <Stat>
                  <StatNumber fontSize="2xl">
                    {metrics.competitionScore < 50 ? "Low" : metrics.competitionScore < 100 ? "Medium" : "High"}
                  </StatNumber>
                  <StatHelpText>{metrics.competitionScore.toFixed(1)}% variance</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Customer Satisfaction</Text>
                <Stat>
                  <StatNumber fontSize="2xl">{metrics.avgRating.toFixed(1)} ⭐</StatNumber>
                  <StatHelpText>Average Rating</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Market Sentiment</Text>
                <Stat>
                  <StatNumber fontSize="2xl" color={
                    parseFloat(metrics.sentimentDistribution.positive) >= 70 ? "green.500" :
                    parseFloat(metrics.sentimentDistribution.positive) >= 50 ? "yellow.500" : "red.500"
                  }>
                    {metrics.sentimentDistribution.positive}%
                  </StatNumber>
                  <StatHelpText>Positive Reviews</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Market Leaders</Text>
                <Stat>
                  <StatNumber fontSize="2xl">
                    {(metrics.marketLeaders[0].share * 1).toFixed(1)}%
                  </StatNumber>
                  <StatHelpText>Top Player Share</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Average Volume</Text>
                <Stat>
                  <StatNumber fontSize="2xl">
                    {(metrics.totalReviews / metrics.businessCount).toFixed(0)}
                  </StatNumber>
                  <StatHelpText>Reviews per Business</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Market Diversity</Text>
                <Stat>
                  <StatNumber fontSize="2xl">
                    {metrics.typeDistribution.length}
                  </StatNumber>
                  <StatHelpText>Business Categories</StatHelpText>
                </Stat>
              </Box>
              <Box>
                <Text fontSize="sm" color="gray.500">Monthly Activity</Text>
                <Stat>
                  <StatNumber fontSize="2xl">
                    {metrics.activityTrendData[metrics.activityTrendData.length - 1]?.count.toLocaleString()}
                  </StatNumber>
                  <StatHelpText>Latest Month Reviews</StatHelpText>
                </Stat>
              </Box>
            </SimpleGrid>
          </Box>

          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Heading size="md">Market Leaders {EMOJIS.crown}</Heading>
              <Button
                size="sm"
                colorScheme="yellow"
                variant="outline"
                onClick={() => navigate('/dashboards/total-reviews')}
                leftIcon={<Icon as={FaChartBar} />}
              >
                View Rankings
              </Button>
            </Flex>
            <VStack spacing={4} align="stretch" maxH="400px" overflowY="auto">
              {metrics.marketLeaders.map((leader, index) => (
                <Flex key={index} justify="space-between" align="center">
                  <HStack>
                    <Text fontSize="xl" fontWeight="bold" color={
                      index === 0 ? "yellow.400" : 
                      index === 1 ? "gray.400" : 
                      index === 2 ? "orange.400" :
                      "blue.400"
                    }>
                      {index === 0 ? "🥇" : index === 1 ? "🥈" : index === 2 ? "🥉" : `#${index + 1}`}
                    </Text>
                    <Box>
                      <Text fontWeight="semibold">{leader.name}</Text>
                      <Text fontSize="sm" color="gray.500">{leader.reviews.toLocaleString()} reviews • {leader.rating} ⭐</Text>
                    </Box>
                  </HStack>
                  <Text fontWeight="bold" color="blue.500">{leader.share}%</Text>
                </Flex>
              ))}
            </VStack>
          </Box>
        </SimpleGrid>

        {/* Market Insights */}
        <SimpleGrid columns={{ base: 1, xl: 2 }} spacing={4}>
          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Heading size="md">Market Activity Trend {EMOJIS.chart}</Heading>
              <Button
                size="sm"
                colorScheme="blue"
                variant="outline"
                onClick={() => navigate('/dashboards/total-reviews')}
                leftIcon={<Icon as={FaChartBar} />}
              >
                View All Reviews
              </Button>
            </Flex>
            <Box height="300px">
              <ResponsiveContainer width="100%" height="100%">
                <LineChart 
                  data={metrics.activityTrendData}
                  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip 
                    formatter={(value) => [value.toLocaleString(), 'Reviews']}
                    labelFormatter={(label) => `${label}`}
                  />
                  <Line 
                    type="monotone" 
                    dataKey="count" 
                    stroke="#4299E1" 
                    strokeWidth={2}
                    dot={{ fill: '#4299E1' }}
                    isAnimationActive={true}
                    animationDuration={1000}
                    animationBegin={0}
                  />
                </LineChart>
              </ResponsiveContainer>
            </Box>
            <Text fontSize="sm" color="gray.500" mt={4}>
              {metrics.marketMomentum >= 0 ? "Growing" : "Declining"} market with {
                Math.abs(metrics.marketMomentum).toFixed(1)
              }% {metrics.marketMomentum >= 0 ? "increase" : "decrease"} in activity
            </Text>
          </Box>

          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Heading size="md">Customer Sentiment {EMOJIS.sentiment}</Heading>
              <Button
                size="sm"
                colorScheme="purple"
                variant="outline"
                onClick={() => navigate('/dashboards/sentiment')}
                leftIcon={<Icon as={FaSmile} />}
              >
                View All Sentiment
              </Button>
            </Flex>
            <Box height="300px">
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={[
                      { name: 'Positive 😊', value: parseFloat(metrics.sentimentDistribution.positive) },
                      { name: 'Neutral 😐', value: parseFloat(metrics.sentimentDistribution.neutral) },
                      { name: 'Negative 😞', value: parseFloat(metrics.sentimentDistribution.negative) }
                    ]}
                    cx="50%"
                    cy="50%"
                    innerRadius={60}
                    outerRadius={100}
                    fill="#8884d8"
                    paddingAngle={5}
                    dataKey="value"
                  >
                    <Cell fill="#48BB78" />
                    <Cell fill="#ECC94B" />
                    <Cell fill="#F56565" />
                  </Pie>
                  <Tooltip formatter={(value) => `${value.toFixed(1)}%`} />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Box>
            <Text fontSize="sm" color="gray.500" mt={4}>
              Overall positive market sentiment with {metrics.sentimentDistribution.positive}% favorable reviews
            </Text>
          </Box>
        </SimpleGrid>

        {/* Additional Market Insights */}
        <SimpleGrid columns={{ base: 1, xl: 3 }} spacing={4}>
          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Heading size="md" mb={4}>Business Types {EMOJIS.target}</Heading>
            <VStack spacing={4} align="stretch">
              <Box>
                <Text fontSize="sm" color="gray.500">Most Common Features</Text>
                {metrics.typeDistribution.map((type, index) => (
                  <HStack key={index} justify="space-between" mt={2}>
                    <Text fontSize="sm">{type.name}</Text>
                    <Text fontSize="sm" fontWeight="bold" color="blue.500">
                      {type.count} ({type.value}%)
                    </Text>
                  </HStack>
                ))}
              </Box>
            </VStack>
          </Box>

          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Flex justify="space-between" align="center" mb={4}>
              <Heading size="md">Recent Trends {EMOJIS.chart}</Heading>
              <Button
                size="sm"
                colorScheme="green"
                variant="outline"
                onClick={() => navigate('/dashboards/gross-growth')}
                leftIcon={<Icon as={FaChartLine} />}
              >
                View All Growth
              </Button>
            </Flex>
            <VStack spacing={4} align="stretch" maxH="400px" overflowY="auto">
              {metrics.recentTrends.map((trend, index) => (
                <Box key={index}>
                  <Flex justify="space-between" align="center">
                    <Text fontSize="sm" fontWeight="semibold">{trend.name}</Text>
                    <Text
                      fontSize="sm"
                      fontWeight="bold"
                      color={trend.trend >= 0 ? "green.500" : "red.500"}
                    >
                      {trend.trend >= 0 ? "+" : ""}{trend.trend.toFixed(1)}%
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="gray.500">
                    {trend.lastMonth} reviews last month (vs {trend.previousMonth})
                  </Text>
                </Box>
              ))}
            </VStack>
          </Box>

          <Box
            bg={bgColor}
            p={6}
            borderRadius="lg"
            boxShadow="sm"
            borderWidth={1}
            borderColor={borderColor}
          >
            <Heading size="md" mb={4}>Primary Categories {EMOJIS.target}</Heading>
            <VStack spacing={3} align="stretch">
              {metrics.primaryTypeDistribution.map((category, index) => (
                <Box key={index}>
                  <Flex justify="space-between" mb={1}>
                    <Text fontSize="sm">{category.name}</Text>
                    <Text fontSize="sm" fontWeight="bold">{category.value}%</Text>
                  </Flex>
                  <Box
                    w="100%"
                    h="2"
                    bg="gray.100"
                    borderRadius="full"
                    overflow="hidden"
                  >
                    <Box
                      w={`${category.value}%`}
                      h="100%"
                      bg={COLORS[index % COLORS.length]}
                      borderRadius="full"
                    />
                  </Box>
                </Box>
              ))}
            </VStack>
          </Box>
        </SimpleGrid>

        {/* Market Recommendations */}
        <Box
          bg={bgColor}
          p={6}
          borderRadius="lg"
          boxShadow="sm"
          borderWidth={1}
          borderColor={borderColor}
        >
          <Heading size="md" mb={4}>Market Insights {EMOJIS.sparkles}</Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
            <Box>
              <Heading size="sm" color="blue.500" mb={2}>Competition {EMOJIS.target}</Heading>
              <Text fontSize="sm">
                {metrics.competitionScore < 50 
                  ? "Low competition with room for growth. Consider aggressive expansion."
                  : metrics.competitionScore < 100
                  ? "Moderate competition. Focus on differentiation and service quality."
                  : "Highly competitive market. Emphasize unique value proposition."}
              </Text>
            </Box>
            <Box>
              <Heading size="sm" color="green.500" mb={2}>Growth Potential {EMOJIS.rocket}</Heading>
              <Text fontSize="sm">
                {metrics.marketMomentum >= 10
                  ? "Strong growth market. Excellent opportunity for expansion."
                  : metrics.marketMomentum >= 0
                  ? "Stable market with moderate growth. Focus on market share."
                  : "Challenging market conditions. Prioritize customer retention."}
              </Text>
            </Box>
            <Box>
              <Heading size="sm" color="purple.500" mb={2}>Customer Focus {EMOJIS.sentiment}</Heading>
              <Text fontSize="sm">
                {parseFloat(metrics.sentimentDistribution.positive) >= 70
                  ? "Highly satisfied customer base. Maintain service excellence."
                  : parseFloat(metrics.sentimentDistribution.positive) >= 50
                  ? "Good customer satisfaction. Room for service improvements."
                  : "Customer satisfaction needs attention. Focus on improvement."}
              </Text>
            </Box>
          </SimpleGrid>
        </Box>
      </VStack>
    </Box>
  );
};

export default HomePage; 