// Navbar.js
import React, { useState } from 'react';
import { 
  Box, 
  VStack, 
  Icon, 
  Tooltip, 
  Text, 
  Flex,
  Image,
  Divider,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Button,
  Portal,
} from '@chakra-ui/react';
import { 
  FaHistory, 
  FaRegCalendarAlt, 
  FaChartBar, 
  FaTools, 
  FaBalanceScale, 
  FaChartLine, 
  FaChartPie, 
  FaChartArea,
  FaAngleRight,
  FaAngleLeft,
  FaSmile,
  FaGlobe,
} from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';

function Navbar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.50', 'gray.700');

  const isActivePath = (path) => location.pathname === path;

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const NavItem = ({ icon, label, path, subItems }) => {
    const isActive = path ? isActivePath(path) : subItems?.some(item => isActivePath(item.path));
    const activeColor = 'blue.500';
    const inactiveColor = useColorModeValue('gray.600', 'gray.400');

    if (subItems) {
      return (
        <Menu placement="right-start" isLazy>
          {({ isOpen }) => (
            <>
              <Tooltip 
                label={label} 
                placement="right" 
                isDisabled={isExpanded || isOpen}
                hasArrow
              >
                <MenuButton
                  as={Button}
                  w="full"
                  h="auto"
                  p={3}
                  variant="ghost"
                  borderRadius="md"
                  cursor="pointer"
                  color={isActive ? activeColor : inactiveColor}
                  bg={isActive ? hoverBgColor : 'transparent'}
                  _hover={{ bg: hoverBgColor }}
                  _active={{ bg: hoverBgColor }}
                  transition="all 0.2s"
                  rightIcon={isExpanded ? <FaAngleRight /> : undefined}
                >
                  <Flex align="center" justify={isExpanded ? "flex-start" : "center"} w="full">
                    <Icon as={icon} boxSize={5} />
                    {isExpanded && <Text ml={3}>{label}</Text>}
                  </Flex>
                </MenuButton>
              </Tooltip>
              <Portal>
                <MenuList 
                  ml={2} 
                  minW="200px"
                  bg={bgColor}
                  borderColor={borderColor}
                  boxShadow="lg"
                  zIndex={1100}
                >
                  {subItems.map((item) => (
                    <MenuItem
                      key={item.path}
                      onClick={() => handleMenuItemClick(item.path)}
                      icon={<Icon as={item.icon} />}
                      color={isActivePath(item.path) ? activeColor : inactiveColor}
                      bg={isActivePath(item.path) ? hoverBgColor : 'transparent'}
                      _hover={{ bg: hoverBgColor }}
                    >
                      {item.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </Portal>
            </>
          )}
        </Menu>
      );
    }

    return (
      <Tooltip 
        label={label} 
        placement="right" 
        isDisabled={isExpanded}
        hasArrow
      >
        <Button
          onClick={() => handleMenuItemClick(path)}
          w="full"
          h="auto"
          p={3}
          variant="ghost"
          borderRadius="md"
          cursor="pointer"
          color={isActive ? activeColor : inactiveColor}
          bg={isActive ? hoverBgColor : 'transparent'}
          _hover={{ bg: hoverBgColor }}
          _active={{ bg: hoverBgColor }}
          transition="all 0.2s"
          leftIcon={<Icon as={icon} boxSize={5} />}
          justifyContent={isExpanded ? "flex-start" : "center"}
        >
          {isExpanded && label}
        </Button>
      </Tooltip>
    );
  };

  return (
    <Box
      position="fixed"
      left={0}
      top={0}
      bottom={0}
      w={isExpanded ? "240px" : "72px"}
      bg={bgColor}
      borderRight="1px"
      borderColor={borderColor}
      transition="all 0.2s"
      zIndex={1000}
      py={4}
      overflowX="hidden"
      overflowY="auto"
      css={{
        '&::-webkit-scrollbar': {
          width: '4px',
        },
        '&::-webkit-scrollbar-track': {
          width: '6px',
        },
        '&::-webkit-scrollbar-thumb': {
          background: borderColor,
          borderRadius: '24px',
        },
      }}
    >
      <VStack spacing={2} align="stretch">
        {/* Logo Section */}
        <Flex 
          px={4} 
          mb={6} 
          align="center" 
          justify="space-between"
          minW={isExpanded ? "240px" : "72px"}
          position="relative"
        >
          <Flex 
            align="center" 
            onClick={() => handleMenuItemClick('/')}
            cursor="pointer"
            _hover={{ opacity: 0.8 }}
            mr={isExpanded ? 8 : 0}
          >
            <Box 
              minW="33px"
              h="33px"
              position="relative"
            >
              <Image 
                src="/output.png" 
                alt="Logo" 
                boxSize="33px"
                position="absolute"
                left={0}
              />
            </Box>
            {isExpanded && (
              <Text fontSize="xl" fontWeight="bold" ml={2}>
                Localytics
                <Text as="span" fontSize="xs" ml={1} color="gray.500">
                  Beta
                </Text>
              </Text>
            )}
          </Flex>
          <Box position="relative" w="24px" h="24px">
            <IconButton
              icon={isExpanded ? <FaAngleLeft /> : <FaAngleRight />}
              variant="ghost"
              size="sm"
              onClick={() => setIsExpanded(!isExpanded)}
              aria-label="Toggle menu"
              _hover={{ bg: hoverBgColor }}
              position="absolute"
              right={0}
              borderRadius="full"
              color="gray.500"
              opacity="0.7"
              _active={{ bg: hoverBgColor }}
              _focus={{ boxShadow: "none" }}
              minW="24px"
              h="24px"
              p={0}
            />
          </Box>
        </Flex>

        {/* Navigation Items */}
        <VStack spacing={1} align="stretch" px={2}>
          <NavItem
            icon={FaChartBar}
            label="Leaderboards"
            subItems={[
              { icon: FaChartArea, label: 'Total Reviews', path: '/dashboards/total-reviews' },
              { icon: FaChartLine, label: 'Gross Growth', path: '/dashboards/gross-growth' },
              { icon: FaChartPie, label: 'Relative Growth', path: '/dashboards/relative-growth' },
              { icon: FaSmile, label: 'Customer Sentiment', path: '/dashboards/sentiment' },
              { icon: FaGlobe, label: 'Web Traffic', path: '/dashboards/web-traffic' },
            ]}
          />
          <NavItem
            icon={FaTools}
            label="Tools"
            subItems={[
              { icon: FaRegCalendarAlt, label: 'Review', path: '/year-in-review' },
              { icon: FaBalanceScale, label: 'Compare', path: '/compare-year' },
            ]}
          />

          <Divider my={2} borderColor={borderColor} />

          <NavItem
            icon={FaHistory}
            label="Changelog"
            path="/changelog"
          />
        </VStack>
      </VStack>
    </Box>
  );
}

export default Navbar;